import React, {useState} from 'react';
import styles from './Map.module.css';
import './Map.css'
import "../lib/svg-map/lib/index.css";
import {SplineEvent} from "@splinetool/react-spline";
import {isMobile} from "react-device-detect";
import udssr from "../assets/udssr";
import {FormControl, FormControlLabel, Switch} from "@mui/material";
import SvgMap from "../lib/svg-map/src/svg-map";
import jsonData from '../assets/map.json'

const Spline = React.lazy(() => import('@splinetool/react-spline'));

function Map() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let spline : any = null;
    let currentEvent: SplineEvent | null = null;

    function onLoad(splineApp: any) {
        // @ts-ignore
        spline = splineApp;
        //console.log(spline.findObjectByName('Shape 13'));
    }

    let onMouseOver = (event: any) => {
        document.querySelector("svg.svg-map")!.removeChild(event.target)
        document.querySelector("svg.svg-map")!.appendChild(event.target)
    }

    let mouseHover = (event: SplineEvent) => {
        currentEvent = event;
    };


    let mouseClick = () => {
        showContent(currentEvent?.target?.name!)
    };

    let locationClick = (event: any) => {
        console.log(event.target.id)
        showContent(event.target.id)
    };

    let showContent = (id: string) => {
        console.log("Clicked on: " + id);
        if (id === undefined) {
            window.location.href = "#"
            window.location.reload()
        } else {
            let contentDiv = document.getElementById(styles.content)!;
            jsonData.forEach((item: any) => {
                let e = item as MapJsonPart
                if (e.id === id) {
                    contentDiv.children[0].textContent = e.name + " (" + e.datum + ")";
                }
            })
            //contentDiv.children[0].textContent = id;
            contentDiv.style.display = "block";
            //scroll.scrollToBottom();
        }
    }

    let [prefersMobileView, setPrefersMobileView] = useState(isMobile);

    if (prefersMobileView) {
        return (
            <div className={styles.Map} id={"map"}>
                <FormControl component={"a"} className={styles.switch}>
                    <FormControlLabel control={<Switch defaultChecked onClick={() => {setPrefersMobileView(!prefersMobileView!)}}/>} label={"Einfache Darstellung"}></FormControlLabel>
                </FormControl>
                <div>
                    <SvgMap map={udssr} onLocationMouseOver={onMouseOver} onLocationClick={locationClick}/>
                </div>
                <div id={styles.content}>
                    <h2 id={styles.contentHeading}></h2>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.Map} id={"map"}>
                <FormControl component={"a"} className={styles.switch}>
                    <FormControlLabel control={<Switch onClick={() => {setPrefersMobileView(!prefersMobileView!)}}/>} label={"Einfache Darstellung"}></FormControlLabel>
                </FormControl>
                <div id={styles.spline}>
                    <Spline scene="https://prod.spline.design/g5QEKuChrc61iUF8/scene.splinecode" onLoad={onLoad} onMouseHover={mouseHover} onClick={mouseClick}/>
                </div>
                <div id={styles.content}>
                    <h2 id={styles.contentHeading}></h2>
                </div>
            </div>
        )
    }
}

interface JsonMapData {
    parts: MapJsonPart[]
}

interface MapJsonPart {
    name: string,
    id: string,
    datum: string
}
//<Suspense fallback={<div className="loader"><CircleLoader color={"#000000"}/></div>}>
//</Suspense>
//
export default Map;
