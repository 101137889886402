import styles from "./Inhalt.module.css"

function Inhalt(props: InhaltsProps) {


    return (
        <div id={props.id} className={styles.container}>
            <h1 id={styles.header}>{props.title}</h1>
            <p className={styles.text}>
                {props.text}
            </p>
        </div>
    )
}

interface InhaltsProps {
    id: string,
    title: string,
    text: string,
}
export default Inhalt;