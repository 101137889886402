import styles from './Homepage.module.css';
import {ReactComponent as HammerSickle} from '../assets/hammer-sickle-svgrepo-com.svg';
import {ReactComponent as Scale} from '../assets/scale-unbalance-svgrepo-com.svg'
import {ReactComponent as Arrow} from '../assets/arrow-double-end-svgrepo-com.svg'
import {ReactComponent as Fist} from '../assets/fist-svgrepo-com.svg'
import {ReactComponent as BreakingChain} from '../assets/breaking-chain-svgrepo-com.svg'
import {ReactComponent as Michail} from "../assets/e.svg";
import {Link} from "react-scroll";
function Homepage() {
    return (
        <div className={styles.Homepage}>
            <div id={styles.headers}>
                <h1 id={styles.heading}>Der Untergang der UdSSR</h1>
                <h2 id={styles.subheading}>Eine Webpräsentation</h2>
            </div>
            <div id={styles.content}>
                <Link className={styles.content} to={'aus'} smooth={true}>
                    <HammerSickle/>
                    <h2 className={styles.contentHeading}>Ausgangssituation</h2>
                </Link>
                <Link className={styles.content} to={'mic'} smooth={true}>
                    <Michail/>
                    <h2 className={styles.contentHeading}>Michail Gorbatschow</h2>
                </Link>
                <Link className={styles.content} to={'dem'} smooth={true}>
                    <Scale/>
                    <h2 className={styles.contentHeading}>Demokratisierung</h2>
                </Link>
                <Link className={styles.content} to={'map'} smooth={true}>
                    <BreakingChain/>
                    <h2 className={styles.contentHeading}>Abspaltung</h2>
                </Link>
                <Link className={styles.content} to={'put'} smooth={true}>
                    <Fist/>
                    <h2 className={styles.contentHeading}>Putschversuch</h2>
                </Link>
                <Link className={styles.content} to={'fol'} smooth={true}>
                    <Arrow/>
                    <h2 className={styles.contentHeading}>Folgen</h2>
                </Link>
            </div>
        </div>
    );
}

export default Homepage;