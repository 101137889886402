import Homepage from "./Homepage";
import Map from "./Map";
import styles from './App.module.css';
import {InView} from "react-intersection-observer";
import {ArrowUpwardSharp} from "@mui/icons-material";
import { animateScroll as scroll } from "react-scroll";
import Inhalt from "./Inhalt";

function App() {
    return (
        <div id={styles.appRoot}>
            <ArrowUpwardSharp onClick={() => {scroll.scrollToTop()}} className={"hidden"} id={styles.scrollTop}/>
            <InView as={"div"} className={styles.fullDiv} onChange={((inView, entry) => {
                console.log(inView)
                if (inView) {
                    document.getElementById(styles.scrollTop)?.classList.add("hidden")
                } else {
                    document.getElementById(styles.scrollTop)?.classList.remove("hidden")
                }
            })}>
                <Homepage/>
            </InView>
            <div className={styles.separator}></div>
            <Inhalt id={"aus"} title={"Ausgangssituation"} text={"Die Sowjetunion steckte in den 1980er-Jahren in einer tiefen Krise. Die Planwirtschaft der UdSSR funktionierte nicht. Die Rüstungsausgaben waren viel zu hoch. Außerdem sank das Wirtschaftswachstum und Versorgungsmängel plagten die Siegermacht. Auch die Staatsbetriebe  waren unproduktiv und angeschlagen. Aus diesen Gründen wuchs die Unzufriedenheit der Bevölkerung. Auch die fehlende Meinungsfreiheit und Unterdrückung Andersdenkender geriet stark in Kritik. Auch die mangelnde Innovationsfähigkeit war hinsichtlich der USA ein Problem. Ein weiterer Punkt, der die UdSSR zum Erliegen bracht, war auch die Ölkrise in den späten 1970er-Jahren, welche die Union von Ölexporten abhängig machte. Durch die fehlenden Devisen, kam es zu mehr Schulden und das Land wurde zusätzlich belastet."}/>
            <Inhalt id={"mic"} title={"Michail Sergejewitsch Gorbatschov"} text={"Michail Sergejewitsch Gorbatschow wurde am 2. März 1931 in Priwolnoje (Russland) geboren. Als Generalsekretär des Zentralkomitees der Kommunistischen Partei der Sowjetunion (KPdSU) wurde er 1990 Staatspräsident der Sowjetunion. Durch seine Demokratisierung leitete er das Ende des kalten Krieges ein. 1990 erhielt er den Friedensnobelpreis. Mit dem Verbot der Kommunistischen Partei in Russland verlor Gorbatschow auch den Job als Generalsekretär. Am 30. August 2022 verstarb der letzte Staatspräsident der Sowjetunion in Moskau."}/>
            <Inhalt id={"dem"} title={"Demokratisierung"} text={"Nachdem Michail Gorbatschow zum Amt des Staatspräsidenten der Sowjetunion antrat, hatte er 2 Reformprozesse: Glasnost und Pestroika. Mit „Glasnost“ erlaubte er den Menschen ihre Meinungen frei äußern zu dürfen. Dies ist sehr revolutionär, da sowas zuvor nie erlaubt war, weshalb die westliche Gorbatschow auch als Segen sah. Die zusammengebrochene Wirtschaft wollte Gorbatschow mit „Pestroika“ wieder aufbauen, indem er Lockerungen in der Zentralverwaltungswirtschaft vornahm, sodass Unternehmen teilweise selbstständige Entscheidungen treffen durften, wie zum Beispiel den Preis und die Menge. Dabei wurden erste Elemente der Marktwirtschaft eingeführt. Folge dessen brach die Wirtschaft ein, die Löhne wurden sehr hoch und die Preise für Produkte stiegen, insbesondere die der Produkte aus dem Westen."}/>
            <Map/>
            <Inhalt id={"put"} title={"Augustputsch in Moskau"} text={"Vom 19. bis zum 21. August 1991 übten Kommunisten der KPdSU, die sich als Staatskomitee für den Ausnahmezustand bezeichneten, in Moskau einen Putsch aus, wobei sie Gorbatschow für abgesetzt erklären. Gorbatschow weilt derweil unter Hausarrest in seinem Ferienhaus auf der Krim. Allerdings gelingt es Boris Jelzin (Präsident Russlands) durch die Bündelung demokratischer Kräfte sich dem Putsch zu widersetzen. Nach dem erfolgslosen Versuch der Einnahme des Parlamentsitzes ist der Putsch gestoppt. Als Gorbatschow wieder zurückkommt, ist er nur noch dem Namen nach Präsident. Kurze Zeit später wurde die Sowjetunion aufgelöst. Boris Jelzin gelang es Gorbatschow die Macht zu entziehen."}/>
            <h1 id={"fol"} className={styles.header}>Folgen</h1>
            <Inhalt id={"kri"} title={"Krim Krise 2014"} text={"Die Krim Krise im Jahr 2014. Grob gesagt ging es um einen Zugehörigkeitsstreit. Die Insel Krim wechselte von 1954 von der Russischen zur Ukrainischen Sowjetrepublik. Nach dem Zerfall der Sowjetunion gehörte die Krim dem unabhängigen ukrainischem Staat, wobei 54% der Wähler im Referendum 1991 wählten, dass die Krim zu der demokratischen Ukraine gehören soll. 1992 war die Krim dann eine autonome Republik innerhalb der Ukraine. Teilweise durch den postsowjetischen Konflikt und früher Einwanderung und anderer Staatszugehörigkeit entwickelten sich Unruhen in der Krim, dass diese Teil der Ukraine ist. Vor allem durch geplante Bündnisse zwischen der Ukraine und der EU wurde die ukrainische Übergangsregierung 2014 mit Arsenij Jazenjuk als Ministerpräsidenten, da Russland so der EU einen Handelspartner vorenthält und die Ukraine weiterhin für die Eurasische Union frei blieb. Erzielt wurde dies durch russische Propaganda, die Demonstrationen schürten, sodass der pro-russische Politiker Sergej Aksjonow eingesetzt wurde. Putin beorderte weitere militärische Einheiten nach einem Hilfeersuchen von Aksjonow dorthin, die die Grenzen schlossen, was von westlichen Staaten als völkerrechtswidriges Verhalten betrachtet wurde. Nach einer Abstimmung wurde die Krim nicht als Teil Russland angesehen, was durch gefälschte Wahlen durch Manipulation begründet worden ist."}/>
            <Inhalt id={"ukr"} title={"Ukraine Krieg"} text={`Historischer Konflikt:
Es gibt Unstimmigkeiten in der Frage: „Was ist die Ukraine?“ Putin glaubt, dass die Ukraine zu einem großen russischen Volk gehören, welches von den Kiew Rus abstammen. Ukrainische Historiker sind der Meinung, dass sie von den Kiewer Rus abstammen und ein eigenständiges Volk sind. Hier sieht man direkt den Konflikt zwischen Russland und Ukraine.
Um die Frage zu klären: „Was sind die Kiewer Rus?“ Die Kiewer Rus, auch Altrussland, Kiewer Russland bzw. Kiewer Reich, war ein mittelalterliches Großreich, das als Vorläuferstaat der heutigen Staaten Russland, Ukraine und Belarus angesehen wird. Der Hauptsitz dieses Reiches war Kiew und Historiker sind sich nicht einig wer der rechtmäßige Erbe der Kiewer Rus ist.
Am 24. August 1991 erklärt die Ukraine ihre Unabhängigkeit von der UdSSR und ihren Austritt aus der Sowjetunion. 90% der ukrainischen Bevölkerung waren für den Austritt. 3 Jahre nach dem Austritt der Ukraine aus der UdSSR unterzeichnete die Ukraine den Atomwaffensperrvertrag und so erklärte Russland die Souveränität der Ukraine zu respektieren. Heutige Umfragen ergeben, dass viele Russen nicht einverstanden mit der ukrainischen Unabhängigkeit sind. 
Was ist der Atomwaffensperrvertrag? Im Atomwaffensperrvertrag verzichten die Unterzeichnerstaaten, die nicht im Besitz von Kernwaffen sind, auf den Erwerb von Atomwaffen.
Putins Meinung zum Zerfall der UdSSR.
Putin bezeichnete den Zusammenbruch der Sowjetunion einst als „größte geopolitische Katastrophe des 20. Jahrhunderts“. Putin spricht von einer „Tragödie“ über den Zerfall der UdSSR. „Das, was wir uns in 1000 Jahren erarbeitet haben, war zu einem bedeutenden Teil verloren“, meint er mit Blick auf das russische Imperium
Man kommt zu dem Schluss, dass Putin dem Großreich hinterher trauert und alles gibt, um Russland wieder groß und mächtig zu machen. Der Zerfall der UdSSR ist ein maßgeblicher Grund für seinen Angriffskrieg in der Ukraine. In mehreren Reden stellt er die Souveränität der Ukraine in Frage und versichert dem Volk dieses Land retten zu wollen. Doch in Wahrheit ist es ein Versuch die große Macht Russlands wieder auszubauen und den Verlust mit dem Zerfall der UdSSR wo Russland über 40% seiner Landmassen verloren hat auszugleichen.
`}/>
        </div>
    )
}

export default App;
